.tutorials {
  margin: 1em 6em;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
}

.tutorial {
  text-align: center;
}

.tutorial-label {
  font-size: 1.25em;
  color: #4c4c4c;
}

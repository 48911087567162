.progression {
  margin: 0.5rem;
  margin-left: -1vw;
  margin-right: -1vw;
  width: 76vw;  
}

.progression-arrow {
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
}

.progression-container {
  width: 80%;
  margin: auto;
}

.show-arrow {
  position: relative;
  background: #88b7d5;
}

.show-arrow:after {
  z-index: 2;
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #333333;
  border-width: 2em 1.5em;
  margin-top: -2em;
}

.show-arrow:first-of-type:after {
  border-left-color: #000000;
}

.progression-number {
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 2em;
  line-height: 2em;
  margin: 0 0.5em;
  color: white;
  opacity: 0.5;
}

.progression-label {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 1.25em;
  line-height: 2em;
  color: #00bbda;
}

.community-grid {
  margin: 1em 6em;
  color: #4c4c4c;
  vertical-align: middle;
}

.community-row {
  display: grid;
  grid-template-columns: 30% 18% auto 18%;
  border-bottom: 1px solid #808080;
  vertical-align: middle;
}

.community-row:last-of-type {
  border: none;
}

.community-cell {
  display: inline-block;
  padding: 1.25em;
  padding-bottom: 0.75em;
  vertical-align: middle;
}

.community-cell.type-notes {
  padding-left: 0;
}

.community-cell.type-Phinch1 {
  opacity: 0.5;
}

.community-cell:first-of-type {
  padding: 1.25em 0.5em;
  padding-right: 2em;
  border-right: 1px solid #808080;
}

.community-cell > p {
  font-size: 0.875em;
}

.modalToggle:hover {
  cursor: pointer;
  opacity: 0.5;
}

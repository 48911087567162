.three-col {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  margin: 0 1rem;
}

.section-third {
  display: inline-block;
  padding: 1em;
}

.section-third.no-top {
  padding-top: 0;
}

.dataset-block {
  background: #000000;
  border-radius: 0.75em;
  padding: 1em;
  padding-bottom: 0.25em;
}

.dataset-block-head {
  height: 2.5em;
}

.dataset-block-heading {
  color: #ffffff;
  font-weight: 600;
}

.dataset-block-subhead {
  color: #ffffff;
  font-size: 0.65em;
  margin: 0.25em 0;
}

.dataset-block-row {
  text-align: right;
  margin-bottom: 0.25em;
}

.dataset-block-row:last-of-type {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #b2b2b2;
}

.dataset-number {
  color: #00bbda;
  font-size: 1.25em;
}

.dataset-label {
  color: #b2b2b2;
  font-size: 0.75em;
}

.dataset-info {
  color: #4c4c4c;
}

.dataset-head {
  height: 3em;
}

.dataset-heading {
  font-size: 1.25em;
  font-weight: 600;
}

.dataset-subhead {
  font-size: 0.75em;
  margin: 0.25em 0;
}

.dataset-p-group {
  border-top: 1px solid #808080;
  padding: 0.5em 0;
  margin: 0.5em 0;
}

.dataset-p {
  font-size: 0.75em;
  margin: 0;
}

.App {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
}

video {
  max-width: 1024px;
}

.segment-center {
  text-align: center;
  margin: 1.25rem auto;
}

.segment-center-video {
  text-align: center;
  margin: 1em 6em;
}

.logo-container {
  display: inline-block;
  background: black;
  width: 3.5rem;
  height: 2.5rem;
  padding: 1.25rem 0.75rem;
}

.logo {
  width: 100%;
  cursor: pointer;
}

.circle-logo {
  background: black;
  width: 5em;
  height: 5em;
  border-radius: 2.5em;
  text-align: center;
  margin: 1.25em auto;
}

.circle-logo .logo {
  width: 70%;
  margin: 1.25em auto;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333333;
  height: 5rem;
  vertical-align: top;
  color: white;
  z-index: 3;
}

.link-container {
  display: inline-block;
  vertical-align: top;
  height: 5rem;
  margin: auto 2em;
}

.link {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin: 1.5rem 1em;
  font-weight: 600;
  color: #999999;
  cursor: pointer;
}

.link.active {
  color: white;
}

.link:hover {
  color: white;
}

.social-container {
  float: right;
  display: inline-block;
  vertical-align: top;
  height: 5rem;
  margin: auto 2em;
  margin-left: 0;
}

.social {
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  height: 1.5rem;
  width: 1.5rem;
  padding: 1.75rem 0.5em;
}

.social .logo {
  vertical-align: top;
  opacity: 0.5;
}

.social .logo:hover {
  opacity: 1;
}

.content {
  position: relative;
  width: 74vw;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 13vw;
  margin-top: calc(5rem + 5vw);
}

.section {
  border-bottom: 1px solid black;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.section:last-of-type {
  padding-bottom: 2em;
  margin-bottom: 0;
}

.section a {
  color: #191919;
}

.section a:hover {
  color: #00bbda;
}

.section-heading {
  text-align: center;
  font-size: 2em;
  padding: 0.5em 2em;
  color: #00bbda;
}

.section-main {
  padding: 0.5em 0;
}

.section-p {
  margin: 1em 6em;
  font-size: 1em;
  line-height: 1.5em;
  color: #4c4c4c;
}

.section-p li {
  font-size: 0.75em;
  line-height: 1.5em;
  margin-bottom: 0.75em;
}

.button {
  display: block;
  background: #00bbda;
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 0.35em 0.5em;
  border-radius: 0.25em;
  cursor: pointer;
  width: 12vw;
  font-size: 0.75em;
  margin: 0.5em auto;
}

.download {
  width: auto;
  font-size: 1.25em;
}

.data {
  width: 100%;
  border-radius: 0;
  margin: 0;
  background: none;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #00bbda;
}

.sub-section {
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.sub-section .section-heading {
  font-size: 1.5em;
  margin: 0 4em;
  border-bottom: 1px solid #00bbda;
}

.sub-section .section-main {
  margin: 0.5em 0;
}

.sub-section .section-main .section-p {
  margin: 0 6em;
}

@media screen and (max-width: 720px) {
  .link-container {
    margin: 0 0.5rem;
  }
  .social-container {
    margin-right: 0;
  }
  .social {
    display: block;
    height: 1rem;
    width: 1rem;
    padding: 1rem 0.5rem;
    padding-left: 0;
    padding-bottom: 0;
  }
  .link {
    font-size: 1.25em;
    margin: 1.5rem 0.5em;
  }
  .slack-modal {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 480px) {
  .logo-container {
    float: left;
  }
  .link-container {
    display: grid;
    float: left;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 0.5rem;
    margin: 0.625rem 0.5rem;
    height: 3.75rem;
  }
  .social {
    padding: 1rem 0.5rem;
    padding-bottom: 0;
  }
  .link {
    font-size: 2.125vw;
    margin: 0;
  }
}
.divider {
  margin-left: -1vw;
  width: 76vw;
  height: 1rem;
}

.divider.mini {
  position: relative;
  width: 100%;
  margin-left: 0;
  margin: 0.5rem 0;
  height: 0.25rem;
}

.segment {
  display: inline-block;
  vertical-align: top;
  height: 0.75rem;
}

.mini .segment {
  height: 0.25rem;
}